<svelte:options immutable={true} />

<script lang="ts">
	import Icon from '@iconify/svelte';
</script>

<div class="w-screen py-20 bg-base-200 mb-40">
	<div class="flex flex-col items-center px-4 w-full max-w-screen-xl mx-auto">
		<span class="uppercase font-mono text-success font-medium tracking-wide">Solution</span>
		<h2 id="solution" class="mx-auto mt-4 text-3xl font-semibold sm:text-4xl md:text-5xl text-center scroll-mt-32">
			It can be Easy and Affordable
		</h2>

		<ul class="mt-4 sm:mt-12 md:mt-16 grid grid-cols-1 lg:grid-cols-3 gap-16">
			<li>
				<div
					class="rounded-full bg-success/10 text-success h-12 aspect-square grid place-content-center"
				>
					<Icon icon="carbon:improve-relevance" width="28" height="28" />
				</div>
				<h3 class="mt-3 text-xl font-semibold">Landing Page that Convert</h3>
				<div class="mt-4 prose">
					<p>
						Just focus on your startup’s content. Our professionally designed templates ensure your
						landing pages look polished and drive results.
					</p>
					<p>No need to worry about design—just edit the content and trust the templates.</p>
				</div>
			</li>
			<li>
				<div
					class="rounded-full bg-success/10 text-success h-12 aspect-square grid place-content-center hue-rotate-[5deg]"
				>
					<Icon icon="ph:book-open-text" width="28" height="28" />
				</div>
				<h3 class="mt-3 text-xl font-semibold">Full Control Over Your Documentation Site</h3>
				<div class="mt-4 prose">
					<p>
						Assemble your documentation pages with specialized components. You control the structure
						and flow of the website, while the template handles the technical connections and
						layout, giving you a streamlined, functional documentation site.
					</p>
				</div>
			</li>
			<li>
				<div
					class="rounded-full bg-success/10 text-success h-12 aspect-square grid place-content-center hue-rotate-[10deg]"
				>
					<Icon icon="carbon:idea" width="28" height="28" />
				</div>
				<h3 class="mt-3 text-xl font-semibold">Built for Startups</h3>
				<div class="mt-4 prose">
					<p>
						Unlike generic website builders, our platform is tailored to tech startups. It’s
						focused, easy to use, and eliminates unnecessary features, allowing you to create what
						you need without the distractions of bloated platforms.
					</p>
				</div>
			</li>
			<li>
				<div
					class="rounded-full bg-success/10 text-success h-12 aspect-square grid place-content-center hue-rotate-15"
				>
					<Icon icon="icon-park-outline:one-one" width="28" height="28" />
				</div>
				<h3 class="mt-3 text-xl font-semibold">One-Time Payment, No Subscriptions</h3>
				<div class="mt-4 prose">
					<p>
						Avoid expensive, recurring fees. With our one-time payment model, you pay once, and can
						come back anytime to update and download again.
					</p>
					<p>Simple, affordable, and without the subscription hassle.</p>
				</div>
			</li>
			<li>
				<div
					class="rounded-full bg-success/10 text-success h-12 aspect-square grid place-content-center hue-rotate-[20deg]"
				>
					<Icon icon="jam:thunder" width="28" height="28" />
				</div>
				<h3 class="mt-3 text-xl font-semibold">Optimized for Speed and SEO</h3>
				<div class="mt-4 prose">
					<p>
						Our templates create websites that are not only visually appealing but also highly
						optimized. They load fast, perform well in audits, and help improve your SEO score,
						giving you an edge in search rankings.
					</p>
				</div>
			</li>
			<li>
				<div
					class="rounded-full bg-success/10 text-success h-12 aspect-square grid place-content-center hue-rotate-[25deg]"
				>
					<Icon icon="uil:link-broken" width="28" height="28" />
				</div>
				<h3 class="mt-3 text-xl font-semibold">No Platform Lock-In</h3>
				<div class="mt-4 prose">
					<p>
						Your website, your rules. With us, you’re free to deploy your site wherever you choose.
						We don’t lock you into any hosting or platform, so you maintain complete control over
						your online presence.
					</p>
				</div>
			</li>
		</ul>
	</div>
</div>

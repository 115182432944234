import { globsToMap } from '$lib/utils/images';

const globs = import.meta.glob('$assets/images/landing/{build.png,edit.png,upload.png}', {
	eager: true,
	query: {
		enhanced: true,
		quality: 100,
		w: '400;680',
		lossless: false
	}
});
export const map = globsToMap(globs);

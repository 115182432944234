<script lang="ts">
	import Icon from '@iconify/svelte';
</script>

<div
	class="px-4 pt-10 pb-20 w-full max-w-screen-2xl mx-auto flex justify-center flex-row flex-wrap gap-12 mb-0"
>
	<div class="flex flex-col gap-6">
		<h2
			id="faq"
			class="max-w-xs text-3xl font-semibold sm:max-w-none sm:text-4xl md:text-5xl scroll-mt-32"
		>
			<span class="inline-block">Frequently</span>
			<span class="inline-block">Asked</span>
			<span class="inline-block">Questions</span>
		</h2>
		<p class="prose-sm sm:prose md:prose-lg text-pretty">
			Quick answers to the most frequently asked questions about our service.
		</p>
	</div>
	<div class="join join-vertical max-w-screen-xl isolate">
		<div
			class="join-item bg-base-100 collapse collapse-arrow hover:bg-base-200 focus-within:bg-base-200 z-0 focus-within:z-10 transition-colors"
		>
			<input type="radio" name="faq" class="w-full" aria-label="Read about support" />
			<div class="collapse-title text-xl font-medium flex items-center gap-2">
				<Icon icon="solar:help-bold" width="28" height="28" class="text-primary" />
				<span>Support</span>
			</div>
			<div class="collapse-content prose-sm sm:prose md:prose-lg text-pretty">
				<p>
					We're Here to Help! Our support team is ready to assist with any issues or questions you
					might have. Reach out anytime for personalized help.
				</p>
				<div class="not-prose mt-2 flex items-center justify-end gap-2">
					<a href="mailto:support@easypagego.com" class="btn btn-outline btn-sm">
						<Icon icon="iconoir:send-mail-solid" width="20" height="20" />
						<span>Write an Email</span>
					</a>
				</div>
			</div>
		</div>
		<div
			class="join-item bg-base-100 collapse collapse-arrow hover:bg-base-200 focus-within:bg-base-200 z-0 focus-within:z-10 transition-colors"
		>
			<input type="radio" name="faq" class="w-full" aria-label="Read about refund policy" />
			<div class="collapse-title text-xl font-medium flex items-center gap-2">
				<Icon
					icon="heroicons:receipt-refund-20-solid"
					width="28"
					height="28"
					class="text-primary hue-rotate-15"
				/>
				<span>Refund Policy</span>
			</div>
			<div class="collapse-content prose-sm sm:prose md:prose-lg text-pretty">
				<p>
					If our tool doesn't meet your expectations, we offer a straightforward refund process.
					Contact us for more details.
				</p>
				<div class="not-prose mt-2 flex items-center justify-end gap-2">
					<a href="mailto:support@easypagego.com" class="btn btn-outline btn-sm">
						<Icon icon="iconoir:send-mail-solid" width="20" height="20" />
						<span>Write an Email</span>
					</a>
				</div>
			</div>
		</div>
		<div
			class="join-item bg-base-100 collapse collapse-arrow hover:bg-base-200 focus-within:bg-base-200 z-0 focus-within:z-10 transition-colors"
		>
			<input
				type="radio"
				name="faq"
				class="w-full"
				aria-label="Read about how to get into our showcase"
			/>
			<div class="collapse-title text-xl font-medium flex items-center gap-2">
				<Icon
					icon="material-symbols:slideshow"
					width="28"
					height="28"
					class="text-primary hue-rotate-30"
				/>
				<span>Showcase</span>
			</div>
			<div class="collapse-content prose-sm sm:prose md:prose-lg text-pretty">
				<p>
					The documentation website itself have been crafted with <strong>easypagego.com</strong>!
				</p>
				<p>
					Want your site in our showcase? Just let us know, and we'll highlight your deployed
					website to inspire others.
				</p>
				<div class="not-prose mt-2 flex items-center justify-end gap-2 flex-wrap">
					<a href="mailto:support@easypagego.com" class="btn btn-primary btn-sm">
						<Icon icon="fluent:trophy-24-filled" width="20" height="20" />
						<span>Submit for Showcase</span>
					</a>
					<a href="https://docs.easypagego.com" target="_blank" class="btn btn-outline btn-sm">
						<Icon icon="mdi:books" width="20" height="20" />
						<span>Open docs website</span>
					</a>
				</div>
			</div>
		</div>
		<div
			class="join-item bg-base-100 collapse collapse-arrow hover:bg-base-200 focus-within:bg-base-200 z-0 focus-within:z-10 transition-colors"
		>
			<input type="radio" name="faq" class="w-full" aria-label="Read about deployment" />
			<div class="collapse-title text-xl font-medium flex items-center gap-2">
				<Icon
					icon="material-symbols:deployed-code-update"
					width="28"
					height="28"
					class="text-primary hue-rotate-[45deg]"
				/>
				<span>Deployment</span>
			</div>
			<div class="collapse-content prose-sm sm:prose md:prose-lg text-pretty">
				<p>Deploy your site however you prefer. Refer to our guides:</p>
				<ul class="not-prose mt-2 list-none list-inside prose flex flex-col items-start">
					<li class="mt-0 mb-1">
						<a
							href="https://docs.easypagego.com/deployment/how-to-deploy-with-cloudflare-pages/"
							target="_blank"
							class="link link-hover inline-flex items-center gap-1.5"
						>
							<Icon icon="skill-icons:cloudflare-light" width="16" height="16" />
							<span> Cloudflare Pages </span>
						</a>
					</li>
					<li class="mt-0 mb-1 tooltip tooltip-right" data-tip="Cooming soon!">
						<span class="btn-disabled inline-flex items-center gap-1.5">
							<Icon icon="skill-icons:github-dark" width="16" height="16" />
							<span> GitHub Pages </span>
						</span>
					</li>
					<li class="mt-0 mb-1 tooltip tooltip-right" data-tip="Cooming soon!">
						<span class="btn-disabled inline-flex items-center gap-1.5">
							<Icon icon="skill-icons:netlify-light" width="16" height="16" />
							<span> Netlify </span>
						</span>
					</li>
					<li class="mt-0 mb-1 tooltip tooltip-right" data-tip="Cooming soon!">
						<span class="btn-disabled inline-flex items-center gap-1.5">
							<Icon icon="skill-icons:docker" width="16" height="16" />
							<span> Self-Host with Docker </span>
						</span>
					</li>
				</ul>
			</div>
		</div>
	</div>
</div>

<svelte:options immutable={true} />

<script lang="ts" context="module">
	declare global {
		interface Window extends WindowWithLemonsqueezy {}
	}

	interface WindowWithLemonsqueezy {
		createLemonSqueezy: () => void;
		LemonSqueezy: {
			/**
			 * Initialises Lemon.js on your page.
			 * @param options - An object with a single property, eventHandler, which is a function that will be called when Lemon.js emits an event.
			 */
			Setup: (options: { eventHandler: (event: { event: string }) => void }) => void;
			/**
			 * Refreshes `lemonsqueezy-button` listeners on the page.
			 */
			Refresh: () => void;

			Url: {
				/**
				 * Opens a given Lemon Squeezy URL, typically these are Checkout or Payment Details Update overlays.
				 * @param url - The URL to open.
				 */
				Open: (url: string) => void;

				/**
				 * Closes the current opened Lemon Squeezy overlay checkout window.
				 */
				Close: () => void;
			};
			Affiliate: {
				/**
				 * Retrieve the affiliate tracking ID
				 */
				GetID: () => string;

				/**
				 * Append the affiliate tracking parameter to the given URL
				 * @param url - The URL to append the affiliate tracking parameter to.
				 */
				Build: (url: string) => string;
			};
		};
	}
</script>

<script lang="ts">
	import { cn } from '$lib/utils/style';
	import BadgeTag from '$lib/components/badge/BadgeTag.svelte';
	import Icon from '@iconify/svelte';
	import { browser } from '$app/environment';
	import { onMount } from 'svelte';
	import { isDark } from '$lib/stores/theme';
	import { PUBLIC_DASHBOARD_BASE_URL } from '$env/static/public';
	import { to } from '$lib/utils/promise';
	import { addToastSimple } from '$lib/components/Toasts.svelte';

	const variants = {
		base: {
			border: 'border-neutral-content',
			cta: '',
			comingSoon: 'badge-primary',
			badge: 'bg-base-300/30 left-0 rounded-br-box'
		},
		bestValue: {
			border: 'border-primary',
			cta: 'btn-primary',
			comingSoon: 'badge-primary',
			badge: 'bg-primary/10 text-primary right-0 rounded-bl-box'
		},
		ai: {
			border: 'border-secondary',
			cta: '',
			comingSoon: 'badge-secondary',
			badge: 'bg-secondary/10 text-secondary rounded-bl-box right-0'
		}
	} as const;

	const options = [
		{
			name: 'starter',
			price: {
				original: '39.99€',
				discounted: '19.99€',
				cadence: 'once'
			},
			badge: {
				icon: 'tabler:discount',
				label: 'Save 20€'
			},
			list: [
				{
					check: true,
					text: 'Access to no-code editor',
					comingSoon: false
				},
				{
					check: true,
					text: 'Landing Page templates',
					comingSoon: false
				},
				{
					check: true,
					text: '<strong>200</strong> Landing Page pages builds',
					comingSoon: false
				}
			],
			cta: {
				onClick: () => getCheckout('good'),
				label: 'Make it land'
			},
			variant: 'base'
		},
		{
			name: 'growth',
			price: {
				original: '59.99€',
				discounted: '24.99€',
				cadence: 'once'
			},
			badge: {
				icon: 'ic:round-star',
				label: 'Save 60%'
			},
			list: [
				{
					check: true,
					text: 'Everything in <strong>Good</strong> plan',
					comingSoon: false
				},
				{
					check: true,
					text: 'Documentation Website templates',
					comingSoon: false
				},
				{
					check: true,
					text: 'Blog templates',
					comingSoon: true
				},
				{
					check: true,
					text: '<strong>200</strong> Landing Page pages builds more',
					comingSoon: false
				},
				{
					check: true,
					text: '<strong>250</strong> Documentation Website pages builds',
					comingSoon: false
				},
				{
					check: true,
					text: 'Customer support',
					comingSoon: false
				}
			],
			cta: {
				onClick: () => getCheckout('better'),
				label: 'Ship it fast'
			},
			variant: 'bestValue'
		},
		{
			name: 'premium',
			price: {
				original: '98.99€',
				discounted: '59.99€',
				cadence: 'once'
			},
			badge: {
				icon: 'tabler:discount',
				label: 'Save 40€'
			},
			list: [
				{
					check: true,
					text: 'Everything in <strong>Better</strong> plan',
					comingSoon: false
				},
				{
					check: true,
					text: '<strong>600</strong> Landing Page pages builds more',
					comingSoon: false
				},
				{
					check: true,
					text: '<strong>750</strong> Documentation Website pages builds more',
					comingSoon: false
				},
				{
					check: true,
					text: 'AI-Powered search',
					comingSoon: true
				},
				{
					check: true,
					text: 'Customer support',
					comingSoon: false
				}
			],
			cta: {
				onClick: () => getCheckout('best'),
				label: 'Get started'
			},
			variant: 'ai'
		}
	] as const;

	let ready = false;
	if (browser)
		onMount(() => {
			const id = setInterval(() => {
				if (window.LemonSqueezy) {
					clearInterval(id);
					window.createLemonSqueezy();
					window.LemonSqueezy.Setup({
						eventHandler(event) {
							console.info(event);
						}
					});
					ready = true;
					return;
				}
			}, 100);

			return () => clearInterval(id);
		});

	async function getCheckout(type: 'good' | 'better' | 'best') {
		const url = new URL(`/api/lemonsqueezy/checkout/${type}`, PUBLIC_DASHBOARD_BASE_URL);
		url.searchParams.set('dark', $isDark ? 'true' : 'false');

		const res = await to(
			fetch(url, {
				method: 'GET',
				mode: 'cors'
			})
		);
		if (res.err) {
			console.error(1, res.err);
			addToastSimple(
				'ls-err',
				'Something went wrong',
				'We could not create the checkout. We are already working on this! (1)'
			);
			return;
		}

		const data = await to(res.data.json());
		if (data.err) {
			console.error(2, res.err);
			addToastSimple(
				'ls-err',
				'Something went wrong',
				'We could not create the checkout. We are already working on this! (2)'
			);
			return;
		}

		if (!data.data.data.checkoutURL) {
			addToastSimple(
				'ls-err',
				'Something went wrong',
				'We could not create the checkout. We are already working on this! (3)'
			);
			return;
		}

		window.LemonSqueezy.Url.Open(data.data.data.checkoutURL);
	}
</script>

<svelte:head>
	<script src="https://app.lemonsqueezy.com/js/lemon.js" defer></script>
</svelte:head>

<div class="flex flex-col items-center px-4 w-full max-w-screen-xl mx-auto mb-40">
	<span class="uppercase font-mono text-primary font-medium tracking-wide text-center">Pricing</span
	>
	<h2
		id="pricing"
		class="mx-auto mt-4 text-3xl font-semibold sm:text-4xl md:text-5xl text-center scroll-mt-32"
	>
		One-time only payments.
	</h2>
	<BadgeTag
		icon="tabler:discount"
		tag="discount"
		label="Secure <u>lifetime discount</u> for alpha pre-orders. Only 30 spots available."
		class="mt-6"
	/>

	<ul class="mt-4 sm:mt-12 md:mt-20 w-full grid grid-cols-1 lg:grid-cols-3 gap-y-8 isolate">
		{#each options as { name, price, list, cta, variant, badge }, index}
			<li
				class={cn(
					index == 1 ? 'z-10' : 'z-0',
					index == 0 ? 'lg:translate-x-2' : index == 2 ? 'lg:-translate-x-2' : 'lg:scale-105'
				)}
			>
				<article
					class={cn(
						'relative',
						'border-2 rounded-box flex flex-col items-center gap-8 p-4 lg:p-6 xl:p-10 bg-base-100',
						variants[variant].border
					)}
				>
					<div
						class={cn(
							'absolute top-0 px-3 py-2 font-semibold flex items-center gap-1',
							variants[variant].badge
						)}
					>
						<Icon icon={badge.icon} width="20" height="20" />
						<span>
							{badge.label}
						</span>
					</div>

					<div class="font-mono uppercase tracking-wide text-lg font-semibold">{name}</div>

					<div class="flex items-start justify-center gap-8 flex-wrap">
						<div class="flex flex-col items-center">
							<s
								class="decoration-transparent font-bold font-mono text-lg strike opacity-90 select-none"
							>
								{price.original}
							</s>
							<div class="font-bold text-5xl tracking-tight">
								{price.discounted}
							</div>
							<div
								class="ml-auto w-fit border-t-2 border-t-neutral-content/70 mt-1 prose font-medium"
							>
								{price.cadence}
							</div>
						</div>

						<ul class="mr-auto">
							{#each list as { text, check, comingSoon }}
								<li class="flex items-start gap-2">
									{#if check}
										<Icon icon="flowbite:check-outline" width="20" height="20" class="flex-shrink-0 mt-2"/>
									{/if}
									<span class="prose-lg">
										{@html text}
									</span>
									{#if comingSoon}
										<span
											class={cn(
												'badge badge-sm badge-outline font-medium',
												variants[variant].comingSoon
											)}
										>
											Coming soon
										</span>
									{/if}
								</li>
							{/each}
						</ul>
					</div>

					<button
						type="button"
						disabled={!ready}
						on:click={cta.onClick}
						class={cn('btn btn-block btn-lg font-mono text-xl', variants[variant].cta)}
					>
						{cta.label}
					</button>
				</article>
			</li>
		{/each}
	</ul>

	<p class="mt-6 sm:mt-10 md:mt-12 mx-auto text-center prose text-sm">
		Payments are processed via an in-site popup through <a
			href="https://www.lemonsqueezy.com/"
			target="_blank"
			rel="noopener noreferrer"
			class="link link-hover inline-flex items-center gap-0.5"
		>
			<Icon icon="simple-icons:lemonsqueezy" width="10" height="10" />
			<span> LemonSqueezy </span>
		</a>.
	</p>
	<p class="mt-1 mx-auto text-center prose text-sm">All prices without VAT.</p>
</div>

<style lang="postcss">
	.strike {
		@apply relative;
		&::after {
			content: '';
			@apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -rotate-6;
			@apply bg-neutral-content/60 w-full h-1 rounded-full;
		}
	}
</style>

<svelte:options immutable={true} />

<script lang="ts">
	import Icon from '@iconify/svelte';
</script>

<div class="flex flex-col items-center px-4 w-full max-w-screen-xl mx-auto mb-40">
	<span class="uppercase font-mono text-error font-medium tracking-wide">Problem</span>
	<h2 id="problem" class="mx-auto mt-4 text-3xl font-semibold sm:text-4xl md:text-5xl text-center scroll-mt-32">
		The Challenge for Startup Founders
	</h2>

	<ul class="mt-4 sm:mt-12 md:mt-16 grid grid-cols-1 lg:grid-cols-3 gap-16">
		<li>
			<div class="rounded-full bg-error/10 text-error h-12 aspect-square grid place-content-center">
				<Icon icon="ph:eye" width="28" height="28" />
			</div>
			<h3 class="mt-3 text-xl font-semibold">Your Product Deserves the Spotlight</h3>
			<div class="mt-4 prose">
				<p>
					As groundbreaking as your product is, without a well-crafted presentation, it can easily
					go unnoticed.
				</p>
				<p>
					A <strong>visually appealing landing page</strong> and
					<strong>clear documentation are essential</strong> for grabbing attention and driving growth.
				</p>
			</div>
		</li>
		<li>
			<div class="rounded-full bg-error/10 text-error h-12 aspect-square grid place-content-center">
				<Icon icon="ph:hourglass" width="28" height="28" />
			</div>
			<h3 class="mt-3 text-xl font-semibold">
				Building Websites Takes Time Away from Your Core Product
			</h3>
			<div class="mt-4 prose">
				<p>
					Setting up a static website involves more than just content. It requires coding, design,
					optimization, and deployment knowledge.
				</p>
				<p>
					Every hour spent building these assets is <strong
						>time diverted from developing your product</strong
					>.
				</p>
			</div>
		</li>
		<li>
			<div class="rounded-full bg-error/10 text-error h-12 aspect-square grid place-content-center">
				<Icon icon="ph:target" width="28" height="28" />
			</div>
			<h3 class="mt-3 text-xl font-semibold">
				Complex, Subscription-Based Tools with Platform Lock-In
			</h3>
			<div class="mt-4 prose">
				<p>
					Many existing solutions are bulky, subscription-based, and take time to learn. On top of
					that, they often lock you into their platform, limiting your flexibility in the long run.
				</p>
				<p>It doesn't have to be like this.</p>
			</div>
		</li>
	</ul>
</div>
